import clsx from 'clsx';
import React from 'react';
import TextInput from './TextInput';

export type Props = {
  className?: string;
};

export default function SecondFactorCode({ className }: Props) {
  return (
    <TextInput
      id={'2fa_code'}
      className={clsx(className)}
      title={'One time code'}
      placeholder={'Enter one time code'}
      required
      minLength={8}
      pattern={'[0-9A-Za-z]+'}
      autoComplete="one-time-code"
      autoFocus
    />
  );
}
